<template>
    <br/>
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="search" class="form-label mt-2 me-2">Keyword</label>
        <input type="text" id="search" class="form-control" placeholder="Post title" v-model="keyword"
               @input="searchKeyword"/>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-1 mt-2">Type</label>
        <select id="per-page" class="form-control" v-model="type" @click="changeType(type)">
          <option value="">All</option>
          <option value="text">Text</option>
          <option value="photo">Photo</option>
          <option value="audio">Audio</option>
          <option value="video">Video</option>
          <option value="youtube">Youtube</option>
          <option value="vimeo">Vimeo</option>
          <option value="livestream">Livestream</option>
          <option value="fundraiser">Fundraiser</option>
          <option value="poll">Poll</option>
        </select>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
        <label for="per-page" class="form-label me-1 mt-2">Per page</label>
        <select id="per-page" class="form-control" v-model="perPage" @click="perPageFilter(perPage)">
          <option value="10" :value="perPage">10</option>
          <option value="20" :value="perPage">20</option>
          <option value="50" :value="perPage">50</option>
        </select>
      </div>
    <div class="table-responsive container-fluid">
      <h4 class="text-center">{{this.channelName}} Posts</h4>
      <router-link :to="{name: 'SingleChannel', params: {id: this.$route.params.id}}">Back to creator</router-link>
      <table class="table">
        <thead>
        <tr>
          <th scope="col"># ID</th>
          <th scope="col">Title</th>
          <th scope="col">Date</th>
          <th scope="col">Type</th>
          <th scope="col">Access type</th>
          <th scope="col">Categories</th>
          <th scope="col">Price</th>
          <th scope="col">Is draft</th>
          <th scope="col">Is published</th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="posts.length > 0" v-for="post in posts">
          <th scope="row">{{post.id}}</th>
          <td><a :href="post.link" target='_blank'>{{post.title}}</a></td>
          <td>{{post.date}}</td>
          <td>{{post.type}}</td>
          <td>
            {{post.access_type}}
            <div v-if="post.access_tiers_name" v-for="tier_name in post.access_tiers_name">
              <div>
                ({{ tier_name }})
              </div>
            </div>
          </td>
          <td>
            <div v-if="post.categories_name.length > 0" v-for="category in post.categories_name">
              <div>
                {{ category.name }}
              </div>
            </div>
            <div v-else>
              None
            </div>
          </td>
          <td>{{post.price / 100}} {{ post.channel_data.currency }}</td>
          <td>{{post.draft ? 'yes' : 'no'}}</td>
          <td>{{this.todaysDateString >= post.date ? 'yes' : 'no'}}</td>
        </tr>
        <tr v-else-if="loading">
          <td colspan="9" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
        </tr>
        <tr v-else>
          <td colspan="9" class="text-center">No posts</td>
        </tr>
        </tbody>
      </table>
      <div class="d-flex justify-content-between" v-if="totalRecords >= 1">
        <div>
          <div class="p-2">
            <p>Total posts: {{ totalRecords }}</p>
          </div>
        </div>
        <div>
          <div class="p-2">
            <nav v-if="totalPages >= 1" aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item" :class="{disabled: currentPage - 1 < 1}">
                  <a @click="paginate(currentPage - 1)" class="page-link">Previous</a>
                </li>
                <li class="page-item" :class="{active: 1 === currentPage}">
                  <a @click="paginate(1)" class="page-link" :class="{'not-clickable': currentPage === 1}">1</a>
                </li>
                <li class="page-item" v-if="currentPage - 2 > 1">
                  <a href="#" class="page-link">...</a>
                </li>
                <li class="page-item" v-if="currentPage - 2 > 1">
                  <a @click="paginate(currentPage - 2)" class="page-link">{{ currentPage - 2}}</a>
                </li>
                <li class="page-item" v-if="currentPage - 1 > 1">
                  <a @click="paginate(currentPage - 1)" class="page-link">{{ currentPage - 1}}</a>
                </li>
                <li class="page-item" v-if="currentPage !== 1"  :class="{active: currentPage}">
                  <a @click="paginate(currentPage)" class="page-link" :class="{'not-clickable': currentPage}">{{currentPage }}</a>
                </li>
                <li class="page-item" v-if="currentPage + 1 <= totalPages">
                  <a @click="paginate(currentPage + 1)" class="page-link">{{ currentPage + 1}}</a>
                </li>
                <li class="page-item" v-if="currentPage + 2 <= totalPages">
                  <a @click="paginate(currentPage + 2)" class="page-link">{{ currentPage + 2}}</a>
                </li>
                <li class="page-item" v-if="currentPage + 2 < totalPages">
                  <a href="#" class="page-link">...</a>
                </li>
                <li class="page-item" v-if="currentPage !== totalPages && currentPage + 2 < totalPages" :class="{active: totalPages === currentPage}">
                  <a @click="paginate(totalPages)" class="page-link" :class="{'not-clickable': totalPages === currentPage}">{{totalPages }}</a>
                </li>
                <li class="page-item" :class="{disabled: currentPage + 1 > totalPages}">
                  <a @click="paginate(currentPage + 1)" class="page-link">Next</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    </div>
  </template>
  
  
  <script>
  import axios from 'axios';
  import _debounce from "lodash/debounce";
  
  export default {
    data() {
      return {
        loading: true,
        disableExport: false,
        type: '',
        keyword: '',
        posts: [],
        channelName: '',
        todaysDateString: '',
        currentPage: 1,
        totalPages: 0,
        totalRecords: 0,
        perPage: 20
      }
    },
  
    mounted() {
      this.todaysDate();
      this.fetchChannelName();
      this.getPosts('', '','', '', 1, 20);
    },
  
    methods: {
      debounceGetPosts: _debounce(function (keyword, type,  page, perPage) {
        this.loading = true;
        axios.get(`/admin/posts/channel/${this.$route.params.id}?keyword=${keyword}&type=${type}&page=${page}&per_page=${perPage}`).then(response => {
          console.log(response.data.data)
          this.posts = response.data.data;
          this.totalPages = response.data.totalPages;
          this.totalRecords = response.data.totalRecords;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
      }, 500),
      getPosts(keyword, type,  page, perPage) {
        this.debounceGetPosts(keyword, type, page, perPage);
      },
      formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
      },
      customDate(date) {
        let dateValue = this[date];
  
        // Check if the input is a valid date
        if (dateValue) {
          // Convert the input to a Date object
          const dateObject = new Date(dateValue);
          this[date] = this.formatDate(dateObject);
        }
      },
      searchDate(date) {
        this.date = date;
        this.getPosts(this.keyword, this.type, this.currentPage, this.perPage);
      },
      changeType(type) {
        this.type = type;
        this.getPosts(this.keyword, this.type,  this.currentPage, this.perPage);
      },
      searchKeyword() {
        this.getPosts(this.keyword, this.type, this.currentPage, this.perPage);
      },
      perPageFilter(perPage) {
        this.perPage = perPage;
        this.getPosts(this.keyword, this.type, this.currentPage, this.perPage);
      },
      paginate(page) {
        this.currentPage = page;
        this.getPosts(this.keyword, this.type,  this.currentPage, this.perPage);
      },
      fetchChannelName() {
          axios.get(`/admin/channel-name/${this.$route.params.id}`)
          .then(response => {
            this.channelName = response.data.data;
          })
          .catch(error => {
            this.channelName = '-';
          });
      },
      todaysDate() {
        let date = new Date();

        let year = date.getFullYear();

        let month = (date.getMonth() + 1).toString().padStart(2, '0');

        let day = date.getDate().toString().padStart(2, '0');

        let hours = date.getHours().toString().padStart(2, '0');

        let minutes = date.getMinutes().toString().padStart(2, '0');

        this.todaysDateString = `${year}-${month}-${day} ${hours}:${minutes}`
      }
    }
  }
  </script>
  