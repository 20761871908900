<template>
    <div class="alert alert-success" role="alert" v-if="successMessage">
      {{ successMessage}}
    </div>
    <div class="alert alert-danger" role="alert" v-if="errorMessage">
      {{ errorMessage}}
    </div>
    <div style="padding:5px"  class="row">
        <div class="col-lg-3">
        <label for="rss-generate" class="form-label mt-3">Select creator</label>
        <select id="rss-generate" class="form-control" v-model="generateRssChannelSelect">
        <option v-for="channel in channels" :value="channel.id">
            {{ channel.name }}
        </option>
    </select>
    <button class="btn btn-primary mt-3 mb-3" @click="generateRss">Generate rss</button>
    </div>
    </div>
    <div style="padding:5px"  class="row">
        <div class="table-responsive container-fluid">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col"># ID</th>
                    <th scope="col">Channel name</th>
                    <th scope="col">RSS Link</th>
                    <th scope="col">Created at</th>
                    <th scope="col">Expiration date</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-if="rss.length > 0" v-for="singleRss in rss">
                    <th scope="row">{{ singleRss.id }}</th>
                    <td><router-link v-if="singleRss.channel" :to="{name: 'SingleChannel',params: {id: singleRss.channel.id}}" target="_blank">
                        {{singleRss.channel.name}}
                        </router-link>
                        <p v-else>-</p>
                    </td>
                    <td><a target="_blank" :href="singleRss.rss_link">Click here</a></td>
                    <td>{{ singleRss.created_at }}</td>
                    <td>{{ singleRss.expiration_date }}</td>
                    </tr>
                    <tr v-else-if="loading">
                    <td colspan="8" class="text-center"><font-awesome-icon icon="fa-solid fa-sync fa-10x" spin/></td>
                    </tr>
                    <tr v-else>
                    <td colspan="8" class="text-center">No Rss</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
  
  
<script>
import axios from 'axios';
import _debounce from "lodash/debounce";

export default {
    data() {
        return {
            loading: true,
            rss: [],
            channels: [],
            successMessage: '',
            errorMessage: '',
            generateRssChannelSelect: null,
        }
    },
    mounted() {
        this.fetchUserRss();
        this.fetchUserSubscribedChannels();
    },
    methods: {
        fetchUserRss() {
            this.loading = true;
            axios.get(`/admin/rss/user/${this.$route.params.id}`)
            .then(response => {
                this.rss = response.data.data;
                this.loading = false;
                console.log(response.data);
            })
            .catch(error => {
                this.loading = false;
                console.log(error);
            });
        },
        fetchUserSubscribedChannels() {
            axios.get(`/admin/channels/subscribed/user/${this.$route.params.id}`)
            .then(response => {
                this.channels = response.data;
                console.log(response.data);
            })
            .catch(error => {
                console.log(error);
            });
        },
        generateRss() {
            if(this.generateRssChannelSelect == null) {
                this.errorMessage = 'please select creator'
                return;
            }
            
            axios.post(`/admin/rss/generate/${this.$route.params.id}/${this.generateRssChannelSelect}`)
            .then(response => {
                this.successMessage = `Token generated: ${response.data.token}`;
                this.fetchUserRss();
                console.log(response.data);
            })
            .catch(error => {
                if(error.response && error.response.data) {
                    this.errorMessage = error.response.data;
                }
                else {
                    this.errorMessage = error;
                }
                console.log(error);
            });
        }
    }
}
</script>