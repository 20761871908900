<template>
  <div id="sidebar-menu">
    <!-- {{layoutobject}}	
    fdsfdsf{{[layoutobject.split(' ').includes('horizontal-wrapper')]}} -->
    <ul class="sidebar-links custom-scrollbar" id="myDIV"
    :style="[layoutobject.split(' ').includes('horizontal-wrapper')  ? layout.settings.layout_type=='rtl'? {'  -right': margin+'px'} : {'margin-left': margin+'px'} :  { margin : '0px'}]"
    >
      <li class="back-btn">
        <router-link to="/">
          <img
            class="img-fluid"
            src="../../assets/images/logo/favicon-32x32.png"
            alt=""
        /></router-link>
        <div class="mobile-back text-end">
          <span>Back</span
          ><i class="fa fa-angle-right ps-2" aria-hidden="true"></i>
        </div>
      </li>
      <li class="sidebar-main-title">
        <div>
          <h6 class="lan-1">General</h6>
        </div>
      </li>
      <li v-if="approvedRoles.includes(role)"  class="sidebar-list">
        <label class="badge badge-light-primary"></label><router-link exact-active-class="active" to="/" class="sidebar-link sidebar-title" href="#">
          <svg class="stroke-icon">
            <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
          </svg>
          <svg class="fill-icon">
            <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
          </svg>
          <span class="lan-3">Dashboard              </span></router-link>
      </li>
      <li v-if="role === 'Ukrainian Manager'" class="sidebar-list">
        <label class="badge badge-light-primary"></label><router-link exact-active-class="active" :to="{name: 'UkrainianHome'}" class="sidebar-link sidebar-title" href="#">
        <svg class="stroke-icon">
          <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
        </svg>
        <svg class="fill-icon">
          <use href="@/assets/svg/icon-sprite.svg#fill-home"></use>
        </svg>
        <span class="lan-3">Ukrainian Dashboard              </span></router-link>
      </li>
      <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link to="/top-creators" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'star']" /><span>Top creators                               </span></router-link></li>
      <li v-if="role === 'Ukrainian Manager'" class="sidebar-list"><router-link :to="{name: 'TopUkraineChannels'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'star']" /><span>Top Ukraine creators                               </span></router-link></li>
      <li class="sidebar-main-title">
        <div>
          <h6 class="lan-1">Manage</h6>
        </div>
      </li>
      <li class="sidebar-list">
        <label class="badge badge-light-primary"></label><router-link exact-active-class="active" to="/users/index" class="sidebar-link sidebar-title" href="#">
          <font-awesome-icon :icon="['fas', 'users']" size="2x" />
        <span class="lan-3">Users              </span></router-link>
      </li>
      <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link :to="{name: 'Channels'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'users-gear']" size="2x" />
        <span>Creators</span></router-link></li>
      <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link :to="{name: 'ContentCategories'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'users-gear']" size="2x" />
        <span>Content categories</span></router-link></li>
      <li v-if="role === 'Ukrainian Manager'" class="sidebar-list"><router-link :to="{name: 'UkrainianChannels'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'users-gear']" size="2x" />
        <span>UA Creators</span></router-link></li>
        <li class="sidebar-main-title">
        <div>
          <h6 class="lan-1">Finances</h6>
        </div>
      </li>
        <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link :to="{name: 'Pledges'}" exact-active-class="active" class="sidebar-link sidebar-title" >
          <font-awesome-icon :icon="['fas', 'rotate']" /><span>Subscriptions                             </span></router-link></li>
      <li v-if="role === 'Ukrainian Manager'" class="sidebar-list"><router-link :to="{name: 'UkrainianPledges'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'rotate']" /><span>UA Subscriptions                             </span></router-link></li>
      <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link :to="{name: 'Payments'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'money-bill']" /><span>Payments                                  </span></router-link></li>
      <li v-if="role === 'Ukrainian Manager'" class="sidebar-list"><router-link :to="{name: 'UkrainianPayments'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'money-bill']" /><span>UA Payments                                  </span></router-link></li>
      <li v-if="approvedRoles.includes(role)" class="sidebar-list"><router-link :to="{name: 'Payouts'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'money-bill-transfer']" /><span>Payouts</span></router-link></li>
      <li class="sidebar-main-title">
        <div>
          <h6 class="lan-1">Reports</h6>
        </div>
      </li>
      <li class="sidebar-list"><router-link to="/reports" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"  /><span>User reports                                  </span></router-link></li>
      <li class="sidebar-list"><router-link to="/surveys" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'square-poll-vertical']" /><span>Sub cancel surveys                                  </span></router-link></li>
      <li class="sidebar-list">
        <router-link to="/user-delete-request" exact-active-class="active" class="sidebar-link sidebar-title" >
          <font-awesome-icon :icon="['fas', 'user-minus']"  />
          <span>
            User delete request
          </span>
        </router-link>
      </li>
      <li v-if="role === superAdmin" class="sidebar-main-title">
        <div>
          <h6 class="lan-1">Admin management</h6>
        </div>
      </li>
      <li v-if="role === superAdmin" class="sidebar-list"><router-link :to="{name: 'UserManagement'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"  /><span>User management                                </span></router-link></li>
      <li v-if="role === superAdmin" class="sidebar-list"><router-link :to="{name: 'Logs'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"  /><span>Logs                                </span></router-link></li>
      <li v-if="role === superAdmin" class="sidebar-list"><router-link :to="{name: 'Accounting'}" exact-active-class="active" class="sidebar-link sidebar-title" >
        <font-awesome-icon :icon="['fas', 'book']"  /><span>Accounting                                </span></router-link></li>
    </ul>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { layoutClasses } from '../../constants/layout';
import store from '../../store';
import UkrainianPayments from "@/pages/dashboard/payments/UkrainianPayments.vue";


export default {
name: 'Navmenu',
data() {
return {
  layoutobj:{},
  role: store.state.auth.user.role,
  //approvedRoles: ['Admin','Super Admin','Ukrainian Manager'],
  approvedRoles: ['Admin', 'Super Admin'],
  superAdmin: 'Super Admin'
};
},
computed: {
UkrainianPayments() {
  return UkrainianPayments
},
...mapState({
  menuItems: state => state.menu.data,
  layout: state => state.layout.layout,
  sidebar: state => state.layout.sidebarType,
  activeoverlay: (state) => state.menu.activeoverlay,
  togglesidebar: (state) => state.menu.togglesidebar,
  width: (state) => state.menu.width,
  height: (state) => state.menu.height,
  margin: (state) => state.menu.margin,
  menuWidth: (state) => state.menu.menuWidth,
}),
layoutobject: {	
  get: function () {	
    return JSON.parse(JSON.stringify(layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout)))[this.layout.settings.layout];	
  },	
  set: function () {	
    this.layoutobj = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);	
    this.layoutobj = JSON.parse(JSON.stringify(this.layoutobj))[this.layout.settings.layout];	
    return this.layoutobj;	
  }	
}
},
watch: {
width() {
  window.addEventListener('resize', this.handleResize);
  this.handleResize();
  window.addEventListener('scroll',this.handleScroll);
  this.handleScroll(); 
  if (window.innerWidth < 992) {
    const newlayout = JSON.parse(JSON.stringify(this.layoutobject).replace('horizontal-wrapper', 'compact-wrapper'));
    document.querySelector('.page-wrapper').className = 'page-wrapper ' + newlayout;
    this.$store.state.menu.margin = 0;
  } else {
    document.querySelector('.page-wrapper').className = 'page-wrapper ' + this.layoutobject;
  }

  if((window.innerWidth < 1199 && this.layout.settings.layout === 'Tokyo') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Moscow') || (window.innerWidth < 1199 && this.layout.settings.layout === 'Rome')) {
    this.menuItems.filter(menuItem => {
      menuItem.active = false;
    });
  }
}
},
created() {

window.addEventListener('resize', this.handleResize);
this.handleResize();
if (this.$store.state.menu.width < 991) {
  this.layout.settings.layout = 'Dubai';	
  this.margin = 0;
}
setTimeout(()=> {
  const elmnt = document.getElementById('myDIV');
  this.$store.state.menu.menuWidth = elmnt.offsetWidth;
  this.$store.state.menu.menuWidth > window.innerWidth  ? 
    (this.$store.state.menu.hideRightArrow = false,this.$store.state.menu.hideLeftArrowRTL = false) : 
    (this.$store.state.menu.hideRightArrow = false,this.$store.state.menu.hideLeftArrowRTL = true);
}, 500);
this.layoutobject = layoutClasses.find((item) => Object.keys(item).pop() === this.layout.settings.layout);	
this.layoutobject = JSON.parse(JSON.stringify(this.layoutobject))[this.layout.settings.layout];
},
destroyed() {
window.removeEventListener('resize', this.handleResize);
},
mounted() {   
this.menuItems.filter(items => {
  if (items.path === this.$route.path)
    this.$store.dispatch('menu/setActiveRoute', items);
  if (!items.children) return false;
  items.children.filter(subItems => {
    if (subItems.path === this.$route.path)
      this.$store.dispatch('menu/setActiveRoute', subItems);
    if (!subItems.children) return false;
    subItems.children.filter(subSubItems => {
      if (subSubItems.path === this.$route.path)
        this.$store.dispatch('menu/setActiveRoute', subSubItems);
    });
  });
});
},
methods: {
handleScroll() {
  if(window.scrollY > 400){
    if(this.layoutobject.split(' ').pop() === 'material-type' || this.layoutobject.split(' ').pop() ==='advance-layout')
      document.querySelector('.sidebar-main').className = 'sidebar-main hovered';
  }else{
    if(document.getElementById('sidebar-main'))
      document.querySelector('.sidebar-main').className = 'sidebar-main';
  }
},
setNavActive(item) {
  this.$store.dispatch('menu/setNavActive', item);
  if(this.layoutobject.split(' ').includes('compact-sidebar') && window.innerWidth > 991) {
    if(this.menuItems.some(menuItem => menuItem.active === true)) {
      this.$store.state.menu.activeoverlay = true;
    } else {
      this.$store.state.menu.activeoverlay = false;
    }
  }
},
hidesecondmenu() {
  if(window.innerWidth < 991) {
    this.$store.state.menu.activeoverlay = false,
    this.$store.state.menu.togglesidebar = false;
    this.menuItems.filter(menuItem => {
      menuItem.active = false;
    });
  } else if(this.layoutobject.split(' ').includes('compact-sidebar')){
    this.$store.state.menu.activeoverlay = false,
    this.menuItems.filter(menuItem => {
      menuItem.active = false;
    });
  }
},
handleResize() {
  this.$store.state.menu.width = window.innerWidth - 450;
},
}
};
</script>
